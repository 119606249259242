import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function RedirectUserPage() {
  const { handle } = useParams();

  useEffect(() => {
    // Construct the custom URL with the slug parameter
    const customURL = 'hudelapp://users?id='+handle;
    console.log(customURL);
    // Use JavaScript to perform the redirection
    window.location.href = customURL;
  }, [handle]);

  return (
    <div className="">
      <p>Redirecting...</p>
    </div>
  );
}

export default RedirectUserPage;
