import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import AdminNavbar from './components/AdminNavbar';


class AdminDashboard extends React.Component {
  constructor(){
    super()
    this.state = {
      email : '',
      domain : '',
      emailSubmitted : false,
      emailSubmitSuccess : false,
      emailErrorMessage : '',
      isLoading : false,
      emailEmpty : false,
      whitelist_domains : [],
      whiteListErrorMessage: '',
      newWhiteListDomainSubmitted : false,
      newWhiteListDomainSuccess : false,
      newWhiteListDomainErrorMessage : '',
      domainSuccessDeteled : false,
      emails :[],
      currentPage: 0, 
      pageSize: 10, 
      pageMetaData: {},
      searchEmail : ''
  }

  }

  componentDidMount(){
    this.getWhitelistDomains()
    this.getEmails(0, '');

  }
  onChange = async (e) =>{
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  
  getWhitelistDomains = async () => {
    this.setState({
      isLoading : true
    })
    try {
      const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Authorization': 'Bearer '+ localStorage.getItem("token"),
      };

      const response = await axios.get('https://hudel-web-backend-development.up.railway.app/api/emails/whitelisted-domains', {
        headers: headers, // Pass headers in the configuration object
      });
      // Handle the response
      // Assuming the response contains data field with relevant information
      if(response.status === 200){
        this.setState({
          whitelist_domains : response.data.content,
          isLoading : false
        })
      }
      // Perform any additional actions after successful login
    } catch (error) {
      if (error.response) {
        this.setState({
          whiteListErrorMessage : error.response.data.errorMessage,
          isLoading : false
        })
      } else {
        console.error(error);
      }
    }
  }

  addWhiteListDomain = async (e) => {
    e.preventDefault();
    try {

      this.setState({
        isLoading : true,
        newWhiteListDomainSubmitted : false,
        newWhiteListDomainErrorMessage : ''
      })

      const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Authorization': 'Bearer '+ localStorage.getItem("token"),
      };

      const response = await axios.post(`https://hudel-web-backend-development.up.railway.app/api/emails/whitelisted-domains/${this.state.domain}`, null, { headers: headers });
      console.log(response.data)
      if (response.status === 200) {
        this.setState({
          newWhiteListDomainSubmitted : true,
          newWhiteListDomainSuccess : true,
          isLoading : false,

        })
        this.getWhitelistDomains()

      } 
    } catch (error) {
      if (error.response) {
        console.log(error.response)
        this.setState({
          isLoading : false,
          newWhiteListDomainSubmitted : true,
          newWhiteListDomainSuccess : false,
          newWhiteListDomainErrorMessage : error.response.data.errorMessage,


        })
      } else {
        console.error(error);
      }
    }
  };

  deleteWhiteListDomain = async (domainName) => {

    try {
      this.notify('Deleting..')

      this.setState({
        isLoading : true,
      })

      const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer '+ localStorage.getItem("token"),
      };

      const response = await axios.delete(`https://hudel-web-backend-development.up.railway.app/api/emails/whitelisted-domains/${domainName}`, { headers: headers });

      console.log(response.status)
      if (response.status === 200) {
        this.notify('Deleted!')

        this.setState({
          isLoading : false,
          domainSuccessDeteled : true
        })

        this.getWhitelistDomains()

      } 
    } catch (error) {
      if (error.response) {
        console.log()
        this.notify(error.response.data.errorMessage)
        this.setState({

          isLoading : false,
          domainSuccessDeteled : false,
          newWhiteListDomainErrorMessage : error.response.data.errorMessage,
        })
      } else {
        console.error(error);
      }
    }
  };

  sendEmail = async (e) => {
    e.preventDefault();
    if(this.state.email === ''){
      this.setState({
        emailEmpty : true
      })
    }
    else{
      try {

        this.setState({
          isLoading : true,
          emailEmpty : false,
          emailSubmitted : false,
          emailErrorMessage : ''
        })

        const headers = {
          'Content-Type': 'application/json',
          'accept': '*/*',
          'Authorization': 'Bearer '+ localStorage.getItem("token"),
        };
  
        const response = await axios.post(`https://hudel-web-backend-development.up.railway.app/api/emails/${this.state.email}`, null, { headers: headers });
        console.log(response.data)
        if (response.status === 200) {
          this.setState({
            emailSubmitted : true,
            emailSubmitSuccess : true,
            isLoading : false
  
          })
          
        } 
      } catch (error) {
        if (error.response) {
          console.log()
          this.setState({
            emailSubmitted : true,
            emailSubmitSuccess : false,
            isLoading : false,
            emailErrorMessage : error.response.data.errorMessage

          })
        } else {
          console.error(error);
        }
      }
    }
  };

  getEmails = async (pageNumber, emailQuery) => {

    this.setState({
      isLoading: true,
    });
  
    try {
      const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
      };
  
      // Build the URL with pagination and email query parameters
      let url = `https://hudel-web-backend-development.up.railway.app/api/emails?page=${pageNumber}`;
      
      if (emailQuery.trim() !== '') {
        url += `&email=${emailQuery}`;
      }
  
      const response = await axios.post(url, null,{
        headers: headers,
      });
  
      if (response.status === 200) {
        this.setState({
          emails: response.data.content,
          pageMetaData: response.data.pageMetaData,
          isLoading: false,
        });
      }
    } catch (error) {
      if (error.response) {
        this.notify(error.response.data.errorMessage);
  
        this.setState({
          isLoading: false,
        });
      } else {
        console.error(error);
      }
    }
  }
  
  

  handlePageChange = (pageNumber) => {
    // Ensure the new page is within bounds
    if (pageNumber >= 0) {
      this.setState({ currentPage: pageNumber }, () => {
        // After updating the current page, fetch emails for the new page
        console.log(pageNumber);
        this.getEmails(pageNumber,''); // Pass the pageNumber directly
      });
    }
  };


  notify = (message) => toast(message);

  render(){

    const { emails, currentPage, pageSize } = this.state;

    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Dashboard 
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{}}>
        <AdminNavbar />
          {/* TOP SECTION */}
          <div className='row m-0 py-5 page-container '>
            <div className='col-12'>
              <h1 style={{color:'white'}}>Hudel Dashboard</h1>
            </div>
            <div className='col-12 col-md-6 pt-4'>
              <div className='white-card p-4'>
                <h5>Add New Whitelist Domain</h5>

                <form onSubmit={this.addWhiteListDomain}   className="form-login w-100 ">
                  {this.state.newWhiteListDomainSuccess === false ?
                  <p style={{color:'red'}}>{this.state.newWhiteListDomainErrorMessage}</p>
                  :
                  <p style={{color:'green'}}>WhiteList Domain Added!</p>
                  }
                  <div className="form-group">
                    <input required type="text" name="domain"  onChange={this.onChange} className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Insert Email Domain"/>
                  </div>
                  {this.state.isLoading === false ?
                  <button  type="submit" className="purple-button btn btn-primary btn-user btn-block mt-4">
                      Add New Domain
                  </button>
                  :
                  <button  disabled className="purple-button btn btn-primary btn-user btn-block mt-4">
                      Loading..
                  </button>

                  }
                </form>
              </div>
            </div>
            <div className='col-12 col-md-6 pt-4'>
              <div className='white-card p-4'>
                <h5>Send Email</h5>
                <form onSubmit={this.sendEmail}  className="form-login w-100">
                  {this.state.emailSubmitSuccess === false ?
                  <p style={{color:'red'}}>{this.state.emailErrorMessage}</p>
                  :
                  <p style={{color:'green'}}>Email sent!</p>
                  }
                  <div className="form-group">
                    <input required type="email" onChange={this.onChange} name="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Insert Email"/>
                  </div>
                  {this.state.isLoading === false ?
                  <button  type="submit" className="purple-button btn btn-primary btn-user btn-block mt-4">
                      Send Link
                  </button>
                  :
                  <button  disabled className="purple-button btn btn-primary btn-user btn-block mt-4">
                      Loading..
                  </button>

                  }
                </form>
              </div>
            </div>

            <div className='col-12 col-md-6 pt-5'>
            <div className='w-100 pb-4'>
              <h5 style={{color:'white'}} className='mb-0'>Whitelist Domain List</h5>
              
            </div>
            <div className='table-responsive'>
              <table class="table table-hover" style={{color:'white'}}>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Domain</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                    {
                    this.state.whitelist_domains.map( (e , index) => {
                      return(
                        <React.Fragment>
                            {
                              <tr>
                                <th scope="row">{index+1}</th>
                                <td>{e}</td>
                                <td>
                                  <button onClick={() =>this.deleteWhiteListDomain(e)} type="button" class="btn btn-danger">Delete</button>
                              </td>
                            </tr>
                            } 
                            </React.Fragment>
                          )
                        })              
                    } 
                </tbody>
              </table>

            </div>
            </div>
            <div className='col-12 col-md-6 pt-5'>
              <div className='w-100 pb-4'>
                <h5 style={{color:'white'}} className='mb-0'>Signed up email list</h5>
                <div className='d-flex justify-content-start pt-2'>
                  <input type="text" onChange={this.onChange} name="searchEmail" className="mr-3" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Insert Email"/>
                  <button type="submit"  onClick={() =>this.getEmails(0,this.state.searchEmail)}  className="purple-button">
                      Search
                  </button>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover" style={{ color: 'white' }}>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Email</th>
                      <th scope="col">Email Status</th>
                      <th scope="col">Eligible Status</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.emails.map( (e , index) => {
                      return(
                        <React.Fragment>
                            {
                              <tr>
                                <th scope="row">{index+1}</th>
                                <td>{e.email}</td>
                                <td style={{ color: e.welcomeMessageSent ? 'green' : 'red' }}>
                                {e.welcomeMessageSent ? 'Sent' : 'Not Sent'}
                              </td>
                              <td style={{ color: e.eligible ? 'green' : 'red' }}>
                                {e.eligible ? 'Eligible' : 'Not Eligible'}
                              </td>
                            </tr>
                            } 
                            </React.Fragment>
                          )
                        })              
                    } 
                  </tbody>
                </table>
              </div>

        {/* Pagination controls */}
        <div className="pagination">
          <button
            className="btn btn-primary"
            disabled={currentPage === 0}
            onClick={() => this.handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <span style={{color:'white'}} className='px-3'>Page {currentPage + 1}</span>
          <button
            className="btn btn-primary"
            disabled={currentPage === this.state.pageMetaData.totalPages - 1}
            onClick={() => this.handlePageChange(currentPage + 1)} // Pass the currentPage here
          >
            Next
          </button>
        </div>
              



              </div>
          </div>
          {/* END OF TOP SECTION */}

        </div> 
        <ToastContainer
            
          autoClose={1000}
        />
      </div>
      )
  }
}

export default AdminDashboard;
