import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import 'font-awesome/css/font-awesome.min.css';
import axios from 'axios';

class BlogList extends React.Component {
  constructor(){

    super()
    this.state = {
      blogs :[],
      currentPage: 0, 
      pageSize: 10, 
      pageMetaData: {}, // Make sure this line is present in your state
      searchBlog : '',
      isLoading : false,
    }

  }

  handlePageChange = (pageNumber) => {
    // Ensure the new page is within bounds
    if (pageNumber >= 0) {
      this.setState({ currentPage: pageNumber }, () => {
        // After updating the current page, fetch emails for the new page
        this.getBlogs(pageNumber,''); // Pass the pageNumber directly
      });
    }
  };

  componentDidMount(){
    this.getBlogs(0, '');
  }

  getBlogs = async (pageNumber, blogQuery) => {

    this.setState({
      isLoading: true,
    });
  
    try {
      const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
      };
  
      // Build the URL with pagination and email query parameters
      let url = `https://hudel-web-backend-development.up.railway.app/api/blogs/find-by-title?page=${pageNumber}`;
      
      if (blogQuery.trim() !== '') {
        url += `&title=${blogQuery}`;
      }
  
      const response = await axios.post(url, null,{
        headers: headers,
      });
  
      if (response.status === 200) {
        this.setState({
          blogs: response.data.content,
          pageMetaData: response.data.pageMetaData,
          isLoading: false,
        });
      }
    } catch (error) {
      if (error.response) {
  
        this.setState({
          isLoading: false,
        });
      } else {
        console.error(error);
      }
    }
  }


  render(){
    const { blogs, currentPage, pageSize } = this.state;

    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Blog - Discover Communities in your School 
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{backgroundColor : '#12083A'}}>
        <Navbar />
          {/* TOP SECTION */}
          <div className='row py-5 page-container max-width' style={{minHeight:"70vh"}}>
            
            <div className='col-12 pb-5 d-flex flex-column justify-content-center align-items-center'>
              <h1 style={{color:'white'}}>Hudel Blog</h1>
              <p className='pt-3' style={{color:'white',textAlign:'center'}}>Explore Our Blog for Inspiring Content and Valuable Insights!</p>
            </div>

            

            {/* Loading Spinner */}
            {this.state.isLoading && (
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div className="spinner-border text-light" role="status">
                  <p className="visually-hidden" style={{ fontFamily: 'Inter Regular'}}>Loading...</p>
                </div>
              </div>
            )}
            {!this.state.isLoading &&
            this.state.blogs.map( (e , index) => {
              return(
                <React.Fragment>
                    {
                        <div className='col-12 col-lg-4 py-4 px-0 px-lg-4' >
                          <div className='blog-card' onClick={()=> window.open("/blog/"+e.id, "_self")}>
                            <img src={e.coverImageUrl} className='img-fluid' style={{width:'100%', height:'150px', maxHeight:'400px',borderRadius:'20px 20px 0px 0px',objectFit:'cover'}} />
                            <div className='p-3' >
                              <div style={{
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 1, 
                                WebkitBoxOrient: 'vertical',
                                textOverflow: 'ellipsis',
                                
                              }}>
                                <big style={{color:'white', fontFamily:'Inter Bold'}} className='mb-0' >
                                {e.title}              
                                </big >
                              </div>
                              <div className='pt-1 d-flex justify-content-between align-items-center '>
                                <small  style={{color:'#ffffff'}}>{e.datePublished}</small>
                                <small  style={{color:'#ffffff'}}>{e.readDurationInMinutes} min read</small>
                              </div>
                              <div className='pt-3' style={{
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 2, 
                                WebkitBoxOrient: 'vertical',
                                textOverflow: 'ellipsis',
                                color:'white'                 
                              }}>
                                <p style={{color:'white', fontFamily:'Inter Regular'}}>
                                {e.shortDescription}</p>
                              </div>  
                              
                              <div className='pt-3 d-flex justify-content-end align-items-center '>
                                <a style={{fontFamily:'Inter Medium', color:'#3F51B5',textDecoration:'none'}} href={'/blog/'+e.id}>
                                    <small>

                                    Read More <span><i className="fa fa-arrow-right"></i></span>
                                    </small>
                                </a>
                              </div>
                            </div>
                          </div>
                      </div>
                    } 
                    </React.Fragment>
                  )
                })              
            } 

            {/* Empty Blogs */}
            {(!this.state.isLoading & this.state.blogs.length === 0) &&
            <div>
              <img src='/assets/Upgrading.png' className='img-fluid' style={{width:'150px'}} />
              <h1 style={{color:'white'}} className='pt-4'>No Blog post yet..</h1>
            </div>
            }
          
            <div className='col-12 pt-5 d-flex justify-content-center align-items-center'>
              <p
                className='mb-0'
                style={{ fontFamily: 'Inter Bold',
                color: currentPage === 0 ? '#777777' : '#FFFFFF',
                cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
              }}
                onClick={() => currentPage !== 0 && this.handlePageChange(currentPage - 1)}
                >
                Previous
              </p>
              <div className='px-4 d-flex justify-content-center align-items-center'>
              {[...Array(this.state.pageMetaData.totalPages).keys()].map((pageNumber) => (
                <p
                  key={pageNumber}
                  className='px-2 mb-0'
                  style={{
                    fontFamily: 'Poppins Medium',
                    color: pageNumber === currentPage ? '#FFFFFF' : '#777777',
                    cursor: 'pointer',
                  }}
                  onClick={() => this.handlePageChange(pageNumber)}
                >
                  {pageNumber + 1}
                </p>
              ))}
              </div>
              <p
                className='mb-0'
                style={{ fontFamily: 'Inter Bold',
                color: currentPage === this.state.pageMetaData.totalPages - 1 ? '#777777' : '#FFFFFF',
                cursor: currentPage === this.state.pageMetaData.totalPages - 1 ? 'not-allowed' : 'pointer',
              }}
                onClick={() => currentPage !== this.state.pageMetaData.totalPages - 1 && this.handlePageChange(currentPage + 1)}
                >
                Next
              </p>
            </div>
            {/* END OF PAGINATION */}



            {/* END OF PAGINATION */}
          </div>
          {/* END OF TOP SECTION */}
        </div> 
        <Footer />

      </div>
      )
  }
}

export default BlogList;
