import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


class LoginPage extends React.Component {
  constructor(){
    super()
    this.state = {
      username : '',
      password : '',
      errorMessage : '',
      submitFailed : false,
      isLoading : false,
  }

  }

  onChange = async (e) =>{
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  signIn = async (e) => {
    e.preventDefault()  

    this.setState({
      isLoading : true
    })
    if(this.state.username === "" || this.state.password === ""){
      this.setState({
        errorMessage : "All fields must be filled!"
      })
    }
    else{
      this.notify('Logging In..')

      try {
        const headers = {
          'Content-Type': 'application/json',
          'accept': '*/*'
        };
        const data = {
          username : this.state.username,
          password : this.state.password
        }
    
        const response = await axios.post('https://hudel-web-backend-development.up.railway.app/api/auth/login', data, { headers: headers });

    
        // Handle the response
        console.log(response.status); // Assuming the response contains data field with relevant information
        if(response.status === 200){
          localStorage.setItem("email",response.data.content.email)
          localStorage.setItem("username",response.data.content.username)
          localStorage.setItem("token",response.data.content.token)
          window.location.href = '/dashboard'
        }
        // Perform any additional actions after successful login
      } catch (error) {
        if (error.response) {
          this.setState({
            errorMessage : error.response.data.errorMessage
          })
        } else {
          console.error(error);
        }
      }
      
    }

    this.setState({
      isLoading : false
    })
  }

  /*{handleSignIn = () => {
      this.props.history.push("/dashboard");
  }}*/
  notify = (message) => toast(message);

  render(){
    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Login 
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{backgroundColor : '#12083A'}}>
        <Navbar />
          {/* TOP SECTION */}
          <div className='row m-0 py-5 page-container ' style={{minHeight:"80vh"}}>
            <div className='col-12 d-flex flex-column justify-content-start align-items-center'>
            <img src='/assets/Upgrading.png' className='img-fluid' style={{width:'150px'}} />
              <h1 style={{color:'white'}}>Hudel Login</h1>
              <form onSubmit={this.signIn} className="form-login pt-5">
                {this.state.errorMessage != '' &&
                <p style={{color:'red',textAlign:'center'}}>{this.state.errorMessage}</p>
                }
                <div className="form-group">
                  <p>
                    <input type="text" onChange={this.onChange} name="username" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Insert Username"/>
                  </p>
                </div>
                <div className="form-group">
                  <p>
                    <input type="password" onChange={this.onChange} name="password" className="form-control form-control-user" id="exampleInputPassword" placeholder="Insert Password"/>
                  </p>
                </div>
                {this.state.isLoading === true ?
                
                <button disabled  type="submit" className="purple-button btn btn-primary btn-user btn-block mt-4">
                    Loading..
                </button>
              :
                <button  type="submit" className="purple-button btn btn-primary btn-user btn-block mt-4">
                    Login
                </button>
              }

                
                  
              </form>
            </div>
          </div>
          {/* END OF TOP SECTION */}

        </div> 
        <Footer />
        <ToastContainer
            
          autoClose={1000}
          />
      </div>
      )
  }
}

export default LoginPage;
