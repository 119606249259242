import React from 'react';

import '../css/index.css';

const AdminNavbar = () => {
  const logout = () => {
    // Remove JWT from localStorage
    localStorage.removeItem('email');
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  return (
    <div className='row page-container py-4 max-width' style={{ backgroundColor: 'none' }}>
      <div className='col-12'>
        <nav className=" p-0 navbar navbar-expand-lg navbar-dark">
          <a className="navbar-brand" href="/dashboard">
            <img id='navbar-logo' src="/assets/Logo.png" alt="Logo" width="auto" height="40" className="d-inline-block align-top" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarToggler">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mr-0 mr-md-5">
                <a className="nav-link px-18" href="/dashboard">Dashboard</a>
              </li>
              <li className="nav-item mr-0 mr-md-5">
                <a className="nav-link px-18" href="/admin/dashboard/blog">Blog</a>
              </li>
              <li className="nav-item mr-0 mr-md-5">
                <a className="nav-link px-18" style={{cursor:'pointer'}} onClick={logout}>Logout</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default AdminNavbar;
