import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

class TermsConditions extends React.Component {
  constructor(){
    super()
    this.state = {
    }

  }

  render(){
    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Terms and Conditions
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{backgroundColor : '#12083A'}}>
        <Navbar />
          {/* TOP SECTION */}
          <div className='row max-width py-5 page-container' >
            <div className='col-12  page-container' style={{color:'white',textAlign:'left'}}>
              <h1 className='py-5' style={{textAlign:'center'}}>TERMS & CONDITIONS</h1>
              <p>By downloading, browsing, accessing, or using this Hudel Application (“Hudel Mobile Application”), you agree to be bound by these Terms and Conditions of Use. We reserve the right to amend these terms and conditions at any time. If you disagree with any of these Terms and Conditions of Use, you must immediately discontinue your access to the Hudel Mobile Application and your use of the services offered on the Hudel Mobile Application. Continued use of the Hudel Mobile Application will constitute acceptance of these Terms and Conditions of Use, as may be amended from time to time.</p>

              <h2 className='py-5'>TERMS TO USE</h2>
              <ul>
                  <li>We are committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you’re paying for.</li>
                  <li>The Hudel app stores and processes personal data that you have provided to us, in order to provide our Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the Hudel app won’t work properly or at all.</li>
                  <li>We cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, we cannot accept responsibility.</li>
                  <li>At some point, we may wish to update the app. The app is available for Android and iOS– the requirements for the system (and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. It is your responsibility to keep the app version up to date. We do not promise that it will always update the app so that it is relevant to you and/or works with the version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, we may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you.</li>
              </ul>

              <h2 className='py-5'>GENERAL TERMS</h2>
              <ul>
                  <li>License Grant; Compliance with Terms of Use. The Application is licensed, not sold, and we reserve all rights not expressly granted in this Agreement. Subject to the terms and conditions hereof, We grant you a personal, nonexclusive, non-transferable, non-sublicensable, limited license to download and use the Application on a mobile device that you own or control.</li>
                  <li>License Restrictions. Except as specifically provided herein, You may not: (i) distribute or make the Application available over a network where it could be used by multiple devices at the same time; (ii) copy the Application; (iii) modify, adapt, translate, reverse engineer, make alterations, decompile, disassemble or make derivative works based on the Application, except as otherwise permitted by law; or (iv) use, rent, loan, sub-license, lease, distribute or attempt to grant other rights to the Application to third parties.</li>
                  <li>Your Warranty. You represent and warrant that: (i) You have the authority to bind Yourself to this Agreement; (ii) Your use of the Application will be solely for purposes that are permitted by this Agreement; and (iii) Your use of the Application will comply with all local and national and laws, rules, and regulations (“Laws”).</li>
                  <li>Privacy. By using the Application, you agree that we may collect and use certain information about you, your mobile device, your use of the Application and the Application’s performance in accordance with the Privacy Policy, as may be amended from time to time.</li>
                  <li>Indemnity. You agree to defend, indemnify and hold us harmless including our affiliates, officers, directors, employees, consultants, agents and anyone providing information or software used in the Application from any and all claims arising from, related to, or incidental to Your use of the Application.</li>
                  <li>Termination. This Agreement is effective until terminated. We may immediately terminate this Agreement at any time at its sole discretion with or without notice to you. Additionally, Your rights under this Agreement will terminate automatically if you fail to comply with any term(s) of this Agreement. Upon termination, all legal rights and licenses granted to You hereunder shall terminate immediately and You shall cease all use of the Application and destroy all copies of the Application. All sections that may be reasonably interpreted to or are intended to survive this Agreement will survive this Agreement.</li>
                  <li>Acknowledgment of Understanding/Entire Agreement. You acknowledge that You have read this Agreement, understand it and agree to be bound by its terms and conditions. You also agree that this Agreement is the complete and exclusive statement of the Agreement between We and You and supersedes all proposals, representations or prior agreements, oral or written, and any other communications between us and You relating to the subject matter of this Agreement.</li>
                  <li>Severability. You agree that the terms and conditions stated in this Agreement are severable. If any paragraph, provision, or clause in this Agreement shall be found or be held to be invalid or unenforceable in any jurisdiction, the remainder of this agreement shall be valid and enforceable.</li>
                  <li>Assignment and Transfer. We may assign, transfer, sell, rent or lend this Agreement, in whole or in part, at any time without notice to You. You may not assign this Agreement or any part of it or any rights to use the Application, in whole or in part, either temporarily or permanently, to any other party. Any attempt to do so is void.</li>
              </ul>

              <h2 className='py-5'>SHARING YOUR CONTENT AND INFORMATION</h2>
              <p>You own all of the content and information you post on the Hudel App, and you can control how it is shared through your privacy and application settings. In addition:</p>
              <ul>
                  <li>For content that is covered by intellectual property rights, like photos and videos (IP content), you specifically give us the following permission, subject to your privacy and application settings: you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any IP content that you post on or in connection with Hudel App (IP License). This IP License ends when you delete your IP content or your account unless your content has been shared with others, and they have not deleted it.</li>
                  <li>When you delete IP content, it is deleted in a manner similar to emptying the recycle bin on a computer. However, you understand that removed content may persist in backup copies for a reasonable period of time (but will not be available to others).</li>
                  <li>When you use an application, the application may ask for your permission to access your content and information as well as content and information that others have shared with you. We require applications to respect your privacy, and your agreement with that application will control how the application can use, store, and transfer that content and information.</li>
                  <li>When you publish content or information, it means that you are allowing everyone, including people of Hudel App, to access and use that information, and to associate it with you (i.e., your name and profile picture).</li>
                  <li>We always appreciate your feedback or other suggestions about Hudel App, but you understand that we may use your feedback or suggestions without any obligation to compensate you for them (just as you have no obligation to offer them).</li>
              </ul>

              <h2 className='py-5'>SAFETY</h2>
              <p>We do our best to keep Hudel App safe, but we cannot guarantee it. We need your help to keep Hudel App safe, which includes the following commitments by you:</p>
              <ol>
                  <li>You will not post unauthorized commercial communications (such as spam) on Hudel App.</li>
                  <li>You will not collect users' content or information, or otherwise access Hudel App, using automated means (such as harvesting bots, robots, spiders, or scrapers) without our prior permission.</li>
                  <li>You will not engage in unlawful multi-level marketing, such as a pyramid scheme, on Hudel App.</li>
                  <li>You will not upload viruses or other malicious code.</li>
                  <li>You will not solicit login information or access an account belonging to someone else.</li>
                  <li>You will not bully, intimidate, or harass any user.</li>
                  <li>You will not post content that: is hate speech, threatening, or pornographic; incites violence or contains nudity or graphic or gratuitous violence.</li>
                  <li>You will not develop or operate a third-party application containing alcohol-related, or other mature content (including advertisements) without appropriate age-based restrictions.</li>
                  <li>You will not use Hudel App to do anything unlawful, misleading, malicious, or discriminatory.</li>
                  <li>You will not do anything that could disable, overburden, or impair the proper working or appearance of Hudel App, such as a denial-of-service attack or interference with page rendering or other Hudel App functionality.</li>
              </ol>

              <h2 className='py-5'>REGISTRATION AND ACCOUNT SECURITY</h2>
              <p>App users should provide their real names and information, and we need your help to keep it that way. Here are some commitments you make to us relating to registering and maintaining the security of your account:</p>
              <ul>
                  <li>You will not provide any false personal information on our app, or create an account for anyone other than yourself without permission.</li>
                  <li>You will not create more than one personal account.</li>
                  <li>If we disable your account, you will not create another one without our permission.</li>
                  <li>You will not use your personal timeline primarily for your own commercial gain, and will use our app for such purposes.</li>
                  <li>You will not use the app if you are a convicted sex offender.</li>
                  <li>You will keep your contact information accurate and up-to-date.</li>
                  <li>You will not share your password (or in the case of developers, your secret key), let anyone else access your account, or do anything else that might jeopardize the security of your account.</li>
                  <li>You will not transfer your account (including any Page or application you administer) to anyone without first getting our written permission.</li>
                  <li>If you select a username or similar identifier for your account, we reserve the right to remove or reclaim it if we believe it is appropriate (such as when a trademark owner complains about a username that does not closely relate to a user's actual name).</li>
              </ul>

              <h2 className='py-5'>PROTECTING OTHER PEOPLE'S RIGHTS</h2>
              <p>We respect other people's rights and expect you to do the same.</p>
              <ol>
                  <li>You will not post content or take any action on Hudel that infringes or violates someone else's rights or otherwise violates the law.</li>
                  <li>We can remove any content or information you post on the app if we believe that it violates this Statement or our policies.</li>
                  <li>We provide you with tools to help you protect your intellectual property rights. To learn more, visit our How to Report Claims of Intellectual Property Infringement page.</li>
                  <li>If we remove your content for infringing someone else's copyright, and you believe we removed it by mistake, we will provide you with an opportunity to appeal.</li>
                  <li>If you repeatedly infringe other people's intellectual property rights, we will disable your account when appropriate.</li>
                  <li>You will not use our copyrights or Trademarks or any confusingly similar marks, except as expressly permitted by our Brand Usage Guidelines or with our prior written permission.</li>
                  <li>If you collect information from users, you will: obtain their consent, make it clear you (and not Hudel) are the one collecting their information and post a privacy policy explaining what information you collect and how you will use it.</li>
                  <li>You will not post anyone's identification documents or sensitive financial information on Hudel App.</li>
              </ol>

              <h2 className='py-5'>DISCLAIMER AND EXCLUSION OF LIABILITY</h2>
              <ul>
                  <li>The Hudel Mobile Application, the Services, the information on the Hudel Mobile Application, and use of all related facilities are provided on an "as is, as available" basis without any warranties whether express or implied.</li>
                  <li>To the fullest extent permitted by applicable law, we disclaim all representations and warranties relating to the Hudel Mobile Application and its contents, including in relation to any inaccuracies or omissions in the Hudel Mobile Application, warranties of merchantability, quality, fitness for a particular purpose, accuracy, availability, non-infringement or implied warranties from course of dealing or usage of trade.</li>
                  <li>We do not warrant that the Hudel Mobile Application will always be accessible, uninterrupted, timely, secure, error-free or free from computer virus or other invasive or damaging code or that the Hudel Mobile Application will not be affected by any acts of God or other force majeure events, including the inability to obtain or shortage of necessary materials, equipment facilities, power or telecommunications, lack of telecommunications equipment or facilities and failure of information technology or telecommunications equipment or facilities.</li>
                  <li>While we may use reasonable efforts to include accurate and up-to-date information on the Hudel Mobile Application, we make no warranties or representations as to its accuracy, timeliness, or completeness.</li>
                  <li>We shall not be liable for any acts or omissions of any third parties howsoever caused, and for any direct, indirect, incidental, special, consequential or punitive damages, howsoever caused, resulting from or in connection with the Hudel Mobile Application and the services offered in the Hudel Mobile Application, your access to, use of or inability to use the Hudel Mobile Application or the services offered in the Hudel Mobile Application, reliance on or downloading from the Hudel Mobile Application and/or services, or any delays, inaccuracies in the information or in its transmission including but not limited to damages for loss of business or profits, use, data or other intangible, even if we have been advised of the possibility of such damages.</li>
                  <li>We shall not be liable in contract, tort (including negligence or breach of statutory duty) or otherwise howsoever and whatever the cause thereof, for any indirect, consequential, collateral, special or incidental loss or damage suffered or incurred by you in connection with the Hudel Mobile Application and these Terms and Conditions of Use. For the purposes of these Terms and Conditions of Use, indirect or consequential loss or damage includes, without limitation, loss of revenue, profits, anticipated savings or business, loss of data or goodwill, loss of use or value of any equipment including software, claims of third parties, and all associated and incidental costs and expenses.</li>
                  <li>The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer that cannot be excluded or limited are affected.</li>
              </ul>

              <h2 className='py-5'>INTELLECTUAL PROPERTY RIGHTS</h2>
              <ul>
                  <li>All editorial content, information, photographs, illustrations, artwork and other graphic materials, and names, logos and trademarks on the Hudel Mobile Application are protected by copyright laws and/or other laws and/or international treaties, and belong to us and/or our suppliers, as the case may be. These works, logos, graphics, sounds or images may not be copied, reproduced, retransmitted, distributed, disseminated, sold, published, broadcasted or circulated whether in whole or in part, unless expressly permitted by us and/or our suppliers, as the case may be.</li>
                  <li>Nothing contained on the Hudel Mobile Application should be construed as granting by implication, estoppel, or otherwise, any license or right to use any trademark displayed on the Hudel Mobile Application without our written permission. Misuse of any trademarks or any other content displayed on the Hudel Mobile Application is prohibited.</li>
                  <li>We will not hesitate to take legal action against any unauthorized usage of our trade marks, name or symbols to preserve and protect its rights in the matter. All rights not expressly granted herein are reserved. Other product and company names mentioned herein may also be the trademarks of their respective owners.</li>
              </ul>

              <h2 className='py-5'>COPYRIGHT INFRINGEMENTS</h2>
              <p>We respect the intellectual property rights of others. If you believe that any material available on or through the App infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a “Notification”). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the App infringes your copyright, you should consider first contacting an attorney.</p>

              <h2 className='py-5'>CHANGES TO THIS TERMS AND CONDITIONS</h2>
              <p>We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page. These changes are effective immediately after they are posted on this page.</p>

              <h2 className='py-5'>CONTACT US</h2>
              <p>If you have any questions, do not hesitate to contact us at <a href="mailto:email@hudel.co">email@hudel.co</a>.</p>
            </div>
          </div>
          {/* END OF TOP SECTION */}

        </div> 
        <Footer />

      </div>
      )
  }
}

export default TermsConditions;
