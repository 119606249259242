import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw } from 'draft-js';
import {convertToRaw } from 'draft-js';
import axios from 'axios';
import AdminNavbar from './components/AdminNavbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AdminBlogDetail = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState({
    content: '',
    contentImageUrl: '',
    coverImageUrl: '',
    datePublished: '',
    readDurationInMinutes: 0,
    title: '',
    shortDescription : ''
  });

  const [coverImage, setCoverImage] = useState(null); // Add this line
  const [contentImage, setContentImage] = useState(null); // Add this line
  const [editorState, setEditorState] = useState(EditorState.createEmpty()); // Create initial EditorState

  const notify = (message) => toast(message); // Declare notify correctly

  useEffect(() => {
    if (!id) {
      console.error('No id found in URL');
      return;
    }

    getBlogDetail(id);
  }, [id]);

  const getBlogDetail = async (id) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        accept: '*/*',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      };

      let url = `https://hudel-web-backend-development.up.railway.app/api/blogs/find-by-id?id=${id}`;

      const response = await axios.post(url, null, {
        headers: headers,
      });

      console.log(response.data.content)

      // Set state with API response
      setBlogData({
        content: response.data.content.content,
        contentImageUrl: response.data.content.contentImageUrl,
        coverImageUrl: response.data.content.coverImageUrl,
        datePublished: response.data.content.datePublished,
        readDurationInMinutes: response.data.content.readDurationInMinutes,
        title: response.data.content.title,
        shortDescription: response.data.content.shortDescription,
      });
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(response.data.content.content))
        )
      );

    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
      } else {
        console.error(error);
      }
    }
  };

  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const uploadCoverImage = async () => {
    try {
      notify('Uploading..')
      // Assuming you have the blog ID stored in a variable, replace it with the actual blog ID

      const formData = new FormData();
      formData.append('file', coverImage);

      const response = await axios.post(
        `https://hudel-web-backend-development.up.railway.app/api/blogs/${id}/update-cover-image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accept: '*/*',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );

      // Handle the response
      if (response.status === 200) {
        getBlogDetail(id)
        notify('Upload Successful!')
      }
    } catch (error) {
      if (error.response) {
        console.error('Error uploading image:', error.response.data.errorMessage);
      } else {
        console.error(error);
      }
    }
  };

  const handleContentImageChange = (e) => {
    setContentImage(e.target.files[0]);
  };

  const uploadContentImage = async () => {
    try {
      notify('Uploading..')

      // Assuming you have the blog ID stored in a variable, replace it with the actual blog ID

      const formData = new FormData();
      formData.append('file', contentImage);

      const response = await axios.post(
        `https://hudel-web-backend-development.up.railway.app/api/blogs/${id}/update-content-image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accept: '*/*',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );

      // Handle the response
      if (response.status === 200) {
        getBlogDetail(id)
        notify('Upload Successful!')
      }
    } catch (error) {
      if (error.response) {
        console.error('Error uploading image:', error.response.data.errorMessage);
      } else {
        console.error(error);
      }
    }
  };

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlogData((prevBlogData) => ({
      ...prevBlogData,
      [name]: value,
    }));
  };

  const updateBlog = async () => {
    notify('Updating..')

    try {
      const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      };

      const url = `https://hudel-web-backend-development.up.railway.app/api/blogs/${id}`;

      const response = await axios.put(url, blogData, {
        headers: headers,
      });

      // Handle the response
      if (response.status === 200) {
        notify('Updated!')

        console.log('Blog updated successfully:', response.data);
      }
    } catch (error) {
      notify('Updated Error!')

      if (error.response) {
        console.error('Error updating blog:', error.response);
      } else {
        console.error(error);
      }
    }
  };


  return (
    <div className="">
      <Helmet>
        <title>Hudel Admin - Update A Blog</title>
        <meta
          name="description"
          content="Hudel - Discover Communities in your School"
        />
      </Helmet>

      <div className='m-0' style={{}}>
        <AdminNavbar />
          {/* TOP SECTION */}
          <div className='row m-0 py-5 page-container '>
            <div className='col-12'>
              <h1 style={{color:'white'}}>Hudel Update a Blog</h1>
            </div>
          </div>
          {/* END OF TOP SECTION */}
          {/* START OF BLOG LIST */}
          <div className='col-12 page-container'>
            <div className="row">
              <div className='col-6'>
                  <div className="form-group">
                    <p className='mb-0 text-white'>Blog Cover</p>
                    {blogData.coverImageUrl === null ?
                      <p>No Cover Image Uploaded</p>
                      :  
                      <img
                        src={blogData.coverImageUrl} // Assuming contentImageUrl is the URL of the current image
                        alt="Blog Cover's Image"
                        style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
                      />
                    }

                    <div className='d-flex justify-content-between align-items-center mt-3'>
                      <input
                        type="file"
                        name="coverImage"
                        className="form-control form-control-user"
                        onChange={handleCoverImageChange}
                        accept="image/*"

                      />
                      <button onClick={uploadCoverImage} className="mx-3 btn btn-info">
                        Update
                      </button>
                    </div>                      
                  </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Blog Content's Image</p>
                  {blogData.coverImageUrl === null ?
                      <p>No Content Image Uploaded</p>
                      :  
                      <img
                        src={blogData.contentImageUrl} // Assuming contentImageUrl is the URL of the current image
                        alt="Blog Content's Image"
                        style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
                      />
                    }
                    <div className='d-flex justify-content-between align-items-center mt-3'>
                      <input
                        type="file"
                        name="coverImage"
                        className="form-control form-control-user"
                        onChange={handleContentImageChange}
                        accept="image/*"
                      />
                      <button onClick={uploadContentImage} className="mx-3 btn btn-info">
                        Update
                      </button>
                    </div>   
                </div>
              </div>
              <div className='col-12'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Blog Title</p>
                  <input required type="text" 
                  name="title"
                  value={blogData.title}
                  onChange={handleInputChange}
                  className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Insert Blog Title"/>
                </div>
              </div>
              <div className='col-12'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Short Description</p>
                  <textarea required
                  name="shortDescription"
                  value={blogData.shortDescription}
                  onChange={handleInputChange}
                  className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Insert Blog Short Short Description">

                  </textarea>
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Read Duration</p>
                  <input required type="text" 
                  name="readDurationInMinutes"
                  value={blogData.readDurationInMinutes}
                  onChange={handleInputChange}
                  className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Insert Read Duration"/>
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Date Published</p>
                  <input required type="date"
                  name="datePublished"
                  value={blogData.datePublished}
                  onChange={handleInputChange}
                  className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Insert Date Added"/>
                </div>
              </div>
              <div className='col-12'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Blog Content</p>
                  <Editor
                  editorState={editorState}
                  onEditorStateChange={(newEditorState) => {
                    setEditorState(newEditorState);
                    const updatedContent = JSON.stringify(
                      convertToRaw(newEditorState.getCurrentContent())
                    );
                    setBlogData((prevBlogData) => ({
                      ...prevBlogData,
                      content: updatedContent,
                    }));
                  }}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  editorStyle={{ backgroundColor: 'white' }}
                />
                  </div>
              </div>
              <div className='col-12 pb-5'>
                <div className="form-group">
                  <button onClick={updateBlog} className="purple-button btn btn-primary btn-user btn-block mt-4">
                    Update Blog
                  </button>              
                  </div>
              </div>
            </div>
          </div>
          {/* END OF BLOG LIST */}
        </div> 
        <ToastContainer
            
          autoClose={3000}
        />
    </div>
  );
};

export default AdminBlogDetail;
