import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import './index.css';
import HomePage from './HomePage';
import Legal from './Legal';
import TermsConditions from './TermsConditions';

import reportWebVitals from './reportWebVitals';
import UpgradingPage from './UpgradingPage';
import PrivacyPolicy from './PrivacyPolicy';
import Disclaimer from './Disclaimer';
import CommunityGuidelines from './CommunityGuidelines';
import LoginPage from './LoginPage';
import AdminDashboard from './AdminDashboard';
import InvitePage from './InvitePage';
import RedirectPostPage from './RedirectPostPage';
import RedirectUserPage from './RedirectUserPage';
import BlogList from './BlogList';
import BlogDetail from './BlogDetail';
import AdminBlogList from './AdminBlogList';
import AdminBlogDetail from './AdminBlogDetail';
import AdminBlogCreate from './AdminBlogCreate';
import NotFoundPage from './NotFoundPage';

// Create the IsUserRedirect component
function IsUserRedirect({ loggedInPath, children }) {
  const user = localStorage.getItem('token');
  if (user && loggedInPath === '/login') {
    window.location.href = '/dashboard';
    return null;
  }
  return children;
}

// Create the ProtectedRoute component for /dashboard
function ProtectedRoute({ children }) {
  const user = localStorage.getItem('token');
  if (!user) {
    return <Navigate to="/login" />;
  }
  return children;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/legal/terms-and-conditions" element={<TermsConditions />} />
      <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/legal/disclaimer" element={<Disclaimer />} />
      <Route path="/legal/community-guidelines" element={<CommunityGuidelines />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/blog/:id" element={<BlogDetail />} />
      <Route path="/community" element={<UpgradingPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path="/login"
        element={<IsUserRedirect loggedInPath="/login">
          <LoginPage />
        </IsUserRedirect>}
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/blog"
        element={
          <ProtectedRoute>
            <AdminBlogList />
          </ProtectedRoute>
        }
      />
      <Route
        exact path="/admin/dashboard/blog/update/:id"
        element={
          <ProtectedRoute>
            <AdminBlogDetail />
          </ProtectedRoute>
        }
      />
      <Route
        exact path="/admin/dashboard/blog/create"
        element={
          <ProtectedRoute>
            <AdminBlogCreate />
          </ProtectedRoute>
        }
      />
      <Route path="/invite/:slug" element={<InvitePage />} />
      <Route path="/post/:id" element={<RedirectPostPage />} />
      <Route path="/u/:handle" element={<RedirectUserPage />} />

    </Routes>
  </Router>
);

reportWebVitals();
