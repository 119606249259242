import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

class UpgradingPage extends React.Component {
  constructor(){
    super()
    this.state = {
    }

  }

  render(){
    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Not Found - Discover Communities in your School 
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{backgroundColor : '#12083A'}}>
        <Navbar />
          {/* TOP SECTION */}
          <div className='row m-0 py-5 page-container ' style={{minHeight:"70vh"}}>
            <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
              <img src='/assets/Upgrading.png' className='img-fluid' style={{width:'300px'}} />

              <h1 className='pt-3' style={{color:'white',textAlign:"center"}}>
              Hang Tight, We're<br></br>
                <span style={{color:'#6638FF'}}>Upgrading!</span>
                </h1>
            </div>
          </div>
          {/* END OF TOP SECTION */}

        </div> 
        <Footer />

      </div>
      )
  }
}

export default UpgradingPage;
