import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import AdminNavbar from './components/AdminNavbar';


class AdminBlogList extends React.Component {
  constructor(){
    super()
    this.state = {
      blogs :[],
      currentPage: 0, 
      pageSize: 10, 
      pageMetaData: {},
      searchBlog : '',
      isLoading : false,
  }

  }

  componentDidMount(){
    this.getBlogs(0, '');

  }

  onChange = async (e) =>{
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  getBlogs = async (pageNumber, blogQuery) => {

    this.setState({
      isLoading: true,
    });
  
    try {
      const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Authorization': 'Bearer ' + localStorage.getItem("token"),
      };
  
      // Build the URL with pagination and email query parameters
      let url = `https://hudel-web-backend-development.up.railway.app/api/blogs/find-by-title?page=${pageNumber}`;
      
      if (blogQuery.trim() !== '') {
        url += `&title=${blogQuery}`;
      }
  
      const response = await axios.post(url, null,{
        headers: headers,
      });
  
      if (response.status === 200) {
        this.setState({
          blogs: response.data.content,
          pageMetaData: response.data.pageMetaData,
          isLoading: false,
        });
      }
    } catch (error) {
      if (error.response) {
  
        this.setState({
          isLoading: false,
        });
      } else {
        console.error(error);
      }
    }
  }


  handlePageChange = (pageNumber) => {
    // Ensure the new page is within bounds
    if (pageNumber >= 0) {
      this.setState({ currentPage: pageNumber }, () => {
        // After updating the current page, fetch emails for the new page
        console.log(pageNumber);
        this.getBlogs(pageNumber,''); // Pass the pageNumber directly
      });
    }
  };

  deleteBlog = async (blogId) => {

    try {
      this.notify('Deleting..')

      this.setState({
        isLoading : true,
      })

      const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer '+ localStorage.getItem("token"),
      };

      const response = await axios.delete(`https://hudel-web-backend-development.up.railway.app/api/blogs/${blogId}`, { headers: headers });

      if (response.status === 200) {
        this.notify('Deleted!')

        this.getBlogs(this.state.currentPage, '');
        this.setState({
          isLoading : false,
        })


      } 
    } catch (error) {
      if (error.response) {
        this.notify(error.response.data.errorMessage)
        this.setState({
          isLoading : false,
        })
      } else {
        console.error(error);
      }
    }
  };

  notify = (message) => toast(message);

  render(){

    const { blogs, currentPage, pageSize } = this.state;


    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Admin - Blog List
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{}}>
        <AdminNavbar />
          {/* TOP SECTION */}
          <div className='row m-0 py-5 page-container '>
            <div className='col-12'>
              <h1 style={{color:'white'}}>Hudel Blog List</h1>
            </div>
          </div>
          {/* END OF TOP SECTION */}
          {/* START OF BLOG LIST */}
          <div className='col-12 page-container'>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <div className='d-flex w-100 pb-4'>
                <input type="text" name="searchBlog"  onChange={this.onChange} className="mr-3" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Insert Title"/>
                <button type="submit" className="purple-button" onClick={() =>this.getBlogs(0,this.state.searchBlog)}  >
                    {this.state.isLoading ? "Loading.." : "Search"}
                </button>
              </div>  
            </div>
            <div>
              <button onClick={()=> window.open("/admin/dashboard/blog/create", "_self")} type="button" className="purple-button">                     
                {this.state.isLoading ? "Loading.." : "Create New Blog"}
              </button>
  
            </div>

          </div>
          
          {(!this.state.isLoading & this.state.blogs.length === 0) &&
            <div>
              <h1 style={{color:'white'}} className='pt-4'>No Blog post yet..</h1>
            </div>
          }         

          <div className="table-responsive">
                <table className="table table-hover" style={{ color: 'white' }}>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Blog Title</th>
                      <th scope="col">Date Published</th>
                      <th scope="col">Read Duration</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.blogs.map( (e , index) => {
                      return(
                        <React.Fragment>
                            {
                               <tr>
                                  <th scope="row">{index+1}</th>
                                  <td>{e.title}</td>
                                  <td>{e.datePublished}</td>
                                  <td >{e.readDurationInMinutes} min</td>
                                  <td >
                                    <div className='d-flex '>
                                      <button type="button" onClick={() =>this.deleteBlog(e.id)} class="mx-3 btn btn-danger">Delete</button>
                                      <button onClick={()=> window.open("/admin/dashboard/blog/update/"+e.id, "_self")} type="button" class="mx-3 btn btn-info">Edit</button>
                                    </div>
            
                                  </td>
                              </tr>
                            } 
                            </React.Fragment>
                          )
                        })              
                    } 
                  </tbody>
                </table>

                 {/* Pagination controls */}
                 {(!this.state.isLoading & this.state.blogs.length === 0) &&
                  <div className="pagination">
                    <button
                      className="btn btn-primary"
                      disabled={currentPage === 0}
                      onClick={() => this.handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </button>
                    <span style={{color:'white'}} className='px-3'>Page {currentPage + 1}</span>
                    <button
                      className="btn btn-primary"
                      disabled={currentPage === this.state.pageMetaData.totalPages - 1}
                      onClick={() => this.handlePageChange(currentPage + 1)} // Pass the currentPage here
                    >
                      Next
                    </button>
                  </div>
                  }
          </div>
          </div>
          {/* END OF BLOG LIST */}
        </div> 
        <ToastContainer
            
          autoClose={1000}
        />
      </div>
      )
  }
}

export default AdminBlogList;
