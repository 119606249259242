import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

class Disclaimer extends React.Component {
  constructor(){
    super()
    this.state = {
    }

  }

  render(){
    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Disclaimer
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{backgroundColor : '#12083A'}}>
        <Navbar />
          {/* TOP SECTION */}
          <div className='row max-width py-5 page-container '>
            <div className='col-12  page-container' style={{color:'white',textAlign:'left'}}>
            <h1 style={{textAlign:'center'}}className='py-5'>DISCLAIMER</h1>
    <p>If you require any more information or have any questions about our app disclaimer, please feel free to contact us by email at <a href="mailto:email@hudel.co">email@hudel.co</a>.</p>
    <p>By downloading, accessing, or using Hudel Mobile App or any page of this app, you signify your assent to this disclaimer. The contents of this app, including without limitation, all data, information, text, graphics, links, and other materials are provided as a convenience to our app users and are meant to be used for informational purposes only. We do not take responsibility for decisions taken by the reader based solely on the information provided in this app.</p>
    <p>Hudel (hereinafter referred to as "the App") wishes to make clear that any content, materials, or creations generated or facilitated through the use of Hudel are entirely independent and not affiliated with any universities or academic institutions. We, the creators of Hudel, do not maintain any official or contractual relationships with universities, nor do we purport to represent or act on behalf of any educational institution.</p>
    <p>Users of Hudel are hereby explicitly notified and acknowledge that:</p>
    <ul>
        <li>Hudel is a standalone tool designed for creative and educational purposes, and any content or work generated using Hudel does not carry any official endorsement, authorization, or affiliation with any university.</li>
        <li>We disclaim any responsibility for the accuracy, completeness, or suitability of any content generated by users within Hudel. Users are solely responsible for ensuring the compliance of their work with their respective educational institution's policies and guidelines.</li>
        <li>Any references to university names, logos, or trademarks within Hudel are for illustrative or descriptive purposes only and should not be construed as an endorsement, sponsorship, or association with the universities.</li>
        <li>We shall not be liable for any potential damages, legal disputes, or consequences that may arise from the use of Hudel or the content created therein. Users are encouraged to exercise caution and seek appropriate permissions when using content generated through Hudel in an academic or professional context.</li>
        <li>Users of Hudel are responsible for adhering to all applicable laws, regulations, and ethical standards related to their academic and professional endeavors, including proper attribution, citation, and compliance with university policies.</li>
        <li>By using Hudel, users agree to abide by these terms and disclaimers and acknowledge that We bear no responsibility for any claims, disputes, or damages that may result from the use of Hudel. We strongly advise users to consult with their respective educational institutions and legal counsel to ensure full compliance with all relevant policies and regulations.</li>
    </ul>
    <p>The app EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. The app makes no warranty that:</p>
    <ol>
        <li>THE APP OR THE CONTENT WILL MEET OR SATISFY YOUR REQUIREMENTS</li>
        <li>THE APP SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR PHONE OR TABLET OR LOSS OF DATA THAT RESULTS FROM YOUR USE OF THE APP OR ITS CONTENT.</li>
    </ol>
    <p>YOU AGREE TO HOLD Hudel MOBILE APP, AND EACH OF ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS FROM AND AGAINST ANY AND ALL CLAIMS, ACTIONS, DEMANDS, LIABILITIES, JUDGMENTS AND SETTLEMENTS, INCLUDING WITHOUT LIMITATION, FROM ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR ANY OTHER CLAIM YOU MAY INCUR IN CONNECTION WITH YOUR USE OF THIS APP, INCLUDING, WITHOUT LIMITATION, ANY ECONOMIC HARM, LOST PROFITS, DAMAGES TO BUSINESS, DATA OR PHONE SYSTEMS, OR ANY DAMAGES RESULTING FROM RELIANCE ON ANY CONTENT OR RESULTING FROM ANY INTERRUPTIONS, WORK STOPPAGES, PHONE OR TABLET FAILURES, DELETION OF FILES, ERRORS, OMISSIONS, INACCURACIES, DEFECTS, VIRUSES, DELAYS, OR MISTAKES OF ANY KIND.</p>
    <p>The app may include inaccuracies and typographical errors. Changes and improvements are periodically made to the app and the information therein. We do not warrant or assume any legal liability or responsibility for the completeness, or usefulness of any information, service.</p>
            </div>
          </div>
          {/* END OF TOP SECTION */}

        </div> 
        <Footer />

      </div>
      )
  }
}

export default Disclaimer;
