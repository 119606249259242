import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw } from 'draft-js';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';



import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import 'font-awesome/css/font-awesome.min.css';

const BlogDetail = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState({
    content: '',
    contentImageUrl: '',
    coverImageUrl: '',
    datePublished: '',
    readDurationInMinutes: 0,
    title: '',
  });

  const [recommendedBlogData, setRecommendedBlogData] = useState({
    blogs : []
  })

  const [editorState, setEditorState] = useState(EditorState.createEmpty()); // Create initial EditorState
  
  useEffect(() => {
    if (!id) {
      console.error('No id found in URL');
      return;
    }
    getRecommendedBlogs();
    getBlogDetail(id);
  }, [id]);

  const getRecommendedBlogs = async () => {

    try {
      const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
      };
  
      // Build the URL with pagination and email query parameters
      let url = `https://hudel-web-backend-development.up.railway.app/api/blogs/suggested-blogs?id=${id}&size=3`;
      
      const response = await axios.post(url, null,{
        headers: headers,
      });

      console.log(response.data.content)
  
      if (response.status === 200) {
        setRecommendedBlogData({
          blogs : response.data.content
        });
      }
    } catch (error) {
      if (error.response) {
        } else {
        console.error(error);
      }
    }
  }

  const getBlogDetail = async (id) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        accept: '*/*',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      };

      let url = `https://hudel-web-backend-development.up.railway.app/api/blogs/find-by-id?id=${id}`;

      const response = await axios.post(url, null, {
        headers: headers,
      });

      // Set state with API response
      setBlogData({
        content: response.data.content.content,
        contentImageUrl: response.data.content.contentImageUrl,
        coverImageUrl: response.data.content.coverImageUrl,
        datePublished: response.data.content.datePublished,
        readDurationInMinutes: response.data.content.readDurationInMinutes,
        title: response.data.content.title,
      });
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(response.data.content.content))
        )
      );

    } catch (error) {
      if (error.response) {
        console.error(error.response.data);
      } else {
        console.error(error);
      }
    }
  };


  return (
    <div className="">
        <Helmet>
            <title>
              {blogData.title}
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{backgroundColor : '#12083A'}}>
        <div style={{ position: 'relative' }} className='desktop-display'>
          <img src={blogData.coverImageUrl} id='blog-cover' style={{ width: '100%', maxHeight: '400px', position: 'absolute', objectFit: 'cover', filter: 'blur(4px)', opacity: '0.8' }} />
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'black', opacity: '1' }} />
        </div>
        <Navbar />
          {/* TOP SECTION */}
          <div className='row py-5 page-container max-width' >
            <div className='col-12 pb-5 desktop-display'  style={{height: '40vh'}}>
              <div style={{marginTop:'4vh'}}>
                <h1 style={{color:'white', textAlign:'center'}}>{blogData.title}</h1>
                <p className='pt-3' style={{color:'white',textAlign:'center'}}>{blogData.datePublished}</p>
              </div>
            </div>

            <div className='col-12 d-flex flex-column justify-content-center align-items-center' >
              <div className='mobile-display d-none text-align-center'>
                <img src={blogData.coverImageUrl} className='pb-5' style={{ width: '100%', maxHeight: '50vh',  objectFit: 'cover' }} />
                <h1 style={{color:'white', textAlign:'center'}}>{blogData.title}</h1>
                <p className='pt-3' style={{color:'white',textAlign:'center'}}>{blogData.datePublished}</p>
              </div>
            </div>

            <div className='col-12 page-container py-5' style={{color:'#FFFFFF'}}>
              <div className='px-lg-5 px-xs-0'>
                
                <Editor
                  editorState={editorState}
                  readOnly
                  toolbarHidden
                  wrapperClassName="contentWrapper"
                />
                <div className='py-5'>
                  <img src={blogData.contentImageUrl} className='desktop-display' style={{ width: '50%'}} />
                  <img src={blogData.contentImageUrl} className='mobile-display d-none' style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />

                </div>
                <p>
                As we rally up to get Hudel to more universities, stay tuned for our updated list <span> <a href='https://www.instagram.com/thehudel' target='_blank'>@thehudel</a> </span> on instagram.
                </p>

              </div>
            </div>
            <div className='row py-5 max-width' style={{minHeight:"70vh"}}>
              <div className='col-12 page-container py-5'>
                <h1 style={{color:'white', textAlign:'center'}}>More Hudel Blogs</h1>
              </div>
              {recommendedBlogData.blogs?.map((e, index) => (
                <div className='col-12 col-lg-4 py-4 px-0 px-lg-4' >
                  <div className='blog-card' onClick={()=> window.open("/blog/"+e.id, "_self")}>
                    <img src={e.coverImageUrl} className='img-fluid' style={{width:'100%', height:'150px', maxHeight:'400px',borderRadius:'20px 20px 0px 0px',objectFit:'cover'}} />
                    <div className='p-3' >
                      <div style={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 1, 
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis',
                        
                      }}>
                        <big style={{color:'white', fontFamily:'Inter Bold'}} className='mb-0' >
                        {e.title}              
                        </big >
                      </div>
                      <div className='pt-1 d-flex justify-content-between align-items-center '>
                        <small  style={{color:'#ffffff'}}>{e.datePublished}</small>
                        <small  style={{color:'#ffffff'}}>{e.readDurationInMinutes} min read</small>
                      </div>
                      <div className='pt-3' style={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 2, 
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis',
                        color:'white'                 
                      }}>
                        <p style={{color:'white', fontFamily:'Inter Regular'}}>
                        {e.shortDescription}</p>
                      </div>  
                      
                      <div className='pt-3 d-flex justify-content-end align-items-center '>
                        <a style={{fontFamily:'Inter Medium', color:'#3F51B5',textDecoration:'none'}} href={'/blog/'+e.id}>
                            <small>

                            Read More <span><i className="fa fa-arrow-right"></i></span>
                            </small>
                        </a>
                      </div>
                    </div>
                  </div>
              </div>
              ))}
              
            </div>
          </div>
          {/* END OF TOP SECTION */}
        </div> 
        <Footer />

      </div>
  )
};


export default BlogDetail;
