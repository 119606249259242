import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function RedirectPostPage() {
  const { id } = useParams();

  useEffect(() => {
    // Construct the custom URL with the slug parameter
    const customURL = 'hudelapp://post?id='+id;
    console.log(customURL);
    // Use JavaScript to perform the redirection
    window.location.href = customURL;
  }, [id]);

  return (
    <div className="">
      <p>Redirecting...</p>
    </div>
  );
}

export default RedirectPostPage;
