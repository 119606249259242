import React from 'react';

import '../css/index.css';

const Navbar = () => {
   

  return (
    <div className='row page-container py-4 ' style={{backgroundColor:'none'}}>
      <div className='col-12'>
        <nav className=" p-0 navbar navbar-expand-lg navbar-dark">
          <a className="navbar-brand" href="/">
            <img id='navbar-logo' src="/assets/Logo.png" alt="Logo" width="auto" height="40" className="d-inline-block align-top" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarToggler">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mr-0 mr-md-5">
                <a className="nav-link px-18" href="/">Home</a>
              </li>
              <li className="nav-item mr-0 mr-md-5">
                <a className="nav-link px-18" href="/legal">Legal</a>
              </li>
              <li className="nav-item mr-0 mr-md-5">
                <a className="nav-link px-18" href="/blog">Blog</a>
              </li>
              <li className="nav-item ">
                <a className="nav-link px-18" href="/community">Community</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
