import React, { useState } from 'react';
import '../css/index.css';





function Footer() {
  return (
    <div className='py-5 px-5 ' style={{background:'linear-gradient(to bottom, #261A59, #12083A)'}}>
        <div className='row max-width page-container pb-5'>
            <div className='col-12 pb-4 d-flex justify-content-center align-items-center'>
                <p className='px-3' id="footer-item" onClick={()=> window.open("/", "_self")}  style={{cursor:'pointer'}}>Home</p>
                <p className='px-3' id="footer-item" onClick={()=> window.open("/legal", "_self")}  style={{cursor:'pointer'}}>Legal</p>
                <p className='px-3' id="footer-item" onClick={()=> window.open("/blog", "_self")}  style={{cursor:'pointer'}}>Blog</p>
                <p className='px-3' id="footer-item" onClick={()=> window.open("/community", "_self")}  style={{cursor:'pointer'}}>Community</p>

            </div>

            <div className='col-lg-4 col-xs-12 pr-0 pr-lg-5 pl-0 mt-5 d-flex flex-column justify-content-start align-items-start'>
                <div className='d-flex align-items-center'>
                    <a href='mailto: email@hudel.co' target='_blank'>
                        <img id='footer-icon' src="/assets/icons/email_icon.png" className='mr-3' style={{width:'20px', height:'20px'}} />
                    </a>
                    <a target='_blank' href='mailto: email@hudel.co' style={{color:'#9B9B9B'}} className='mb-0'>
                    email@hudel.co
                    </a>
                </div>
                {/* <div className='d-flex align-items-center mt-4'> mailto: abc@example.com
                    <img id='footer-icon' src="/assets/icons/map_icon.png" className='mr-3' style={{width:'20px', height:'20px'}} />
                    <p style={{color:'#9B9B9B'}} className='mb-0'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </p>
                </div> */}
            </div>
            <div className='col-lg-4 col-xs-12 pl-0 pl-lg-5 pr-0 mt-5 d-flex flex-column justify-content-start align-items-start'>
                {/* <div className='d-flex align-items-center'>
                    <img id='footer-icon' src="/assets/icons/phone_icon.png" className='mr-3' style={{width:'20px', height:'20px'}} />
                    <p style={{color:'#9B9B9B'}} className='mb-0'>
                    +1 123 123 123 123
                    </p>
                </div> */}
                <div className='d-flex align-items-center'  style={{cursor:'pointer'}} onClick={()=> window.open("https://instagram.com/thehudel", "_self")}>
                    <a href='https://instagram.com/thehudel' target='_blank'>
                        <img id='footer-icon' src="/assets/icons/insta_icon.png" className='mr-3' style={{width:'20px', height:'20px'}} />
                    </a>
                    <a target='_blank' href='https://instagram.com/thehudel' style={{color:'#9B9B9B'}} className='mb-0'>
                    @thehudel
                    </a>
                </div>
                {/* <div className='d-flex align-items-center mt-4'>
                    <img id='footer-icon' src="/assets/icons/fb_icon.png" className='mr-3' style={{width:'20px', height:'20px'}} />
                    <p style={{color:'#9B9B9B'}} className='mb-0'>
                    Hudel
                    </p>
                </div> */}
            </div>
            <div className='desktop-display col-lg-4 col-xs-12 p-0 mt-5 d-flex flex-column justify-content-start align-items-end'>
                <div className='d-flex align-items-center'>
                    <p style={{color:'#9B9B9B'}} className='mb-0'>
                    &copy; 2023 Hudel - All rights reserved <br></br> CV. Inovasi Digital Securindo
                    </p>
                </div>
            </div>
            <div className='mobile-display d-none'>
                <div className='col-lg-4 col-xs-12 p-0 mt-5 d-flex flex-column justify-content-start align-items-start'>
                <div className='d-flex align-items-center'>
                    <p style={{color:'#9B9B9B'}} className='mb-0'>
                    &copy; 2023 Hudel - All rights reserved <br></br> CV. Inovasi Digital Securindo
                    </p>
                </div>
                </div>
            </div>

        </div>
    </div>  
  );
}

export default Footer;