import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

class Legal extends React.Component {
  constructor(){
    super()
    this.state = {
    }

  }

  render(){
    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Legal - Discover Communities in your School 
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{backgroundColor : '#12083A'}}>
        <Navbar />
          {/* TOP SECTION */}
          <div className='row py-5 page-container max-width' style={{minHeight:"70vh"}}>
            <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
              <h1 style={{color:'white'}}>Legal</h1>
              <p className='pt-3' style={{color:'white',textAlign:'center'}}>Find clarity on our policies and terms by navigating through our legal section.</p>
              <div id='legal-content-width' className='row m-0 px-0 px-md-5' style={{width:'70%'}}>
                <div className='col-12 col-lg-6 pt-5'>
                  <div className='legal-white-card' onClick={()=> window.open("/legal/terms-and-conditions", "_self")}>
                    <p className='px-4 mb-0'   style={{cursor:'pointer',color:'#170D41'}}>Terms and Conditions</p>
                  </div>
                </div>
                <div className='col-12 col-lg-6 pt-5'>
                  <div className='legal-white-card' onClick={()=> window.open("/legal/privacy-policy", "_self")} >
                    <p className='px-4 mb-0'  style={{cursor:'pointer',color:'#170D41'}}>Privacy Policy</p>
                  </div>
                </div>
                <div className='col-12 col-lg-6 pt-5'>
                  <div className='legal-white-card' onClick={()=> window.open("/legal/disclaimer", "_self")}>
                    <p className='px-4 mb-0'   style={{cursor:'pointer',color:'#170D41'}}>Disclaimer</p>
                  </div>
                </div>
                <div className='col-12 col-lg-6 pt-5'>
                  <div className='legal-white-card'>
                    <p className='px-4 mb-0' onClick={()=> window.open("/legal/community-guidelines", "_self")}  style={{cursor:'pointer',color:'#170D41'}}>Community Guidelines</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* END OF TOP SECTION */}

        </div> 
        <Footer />

      </div>
      )
  }
}

export default Legal;
