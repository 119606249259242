import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

class PrivacyPolicy extends React.Component {
  constructor(){
    super()
    this.state = {
    }

  }

  render(){
    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Privacy Policy
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{backgroundColor : '#12083A'}}>
        <Navbar />
          {/* TOP SECTION */}
          <div className='row max-width py-5 page-container '>
            <div className='col-12 page-container' style={{color:'white',textAlign:'left'}}>
            <h1 className='py-5' style={{textAlign:'center'}}>PRIVACY POLICY</h1>
              <p>Please read our Privacy Policy carefully as it explains the following:</p>
              <p>In order to use the App, you must first acknowledge and agree to our Privacy Policy. You cannot use the App without first accepting our Privacy Policy.</p>

              <h2 className='py-5'>GENERAL</h2>
              <p>We ("Individual", "us", "we" or "our") recognize and understand the importance of the privacy of its users ("User", "you" or "your") and want to respect their desire to store and access personal information in a private and secure manner. This Privacy Policy applies to our application and describes how we manage, store, and utilize your Personal Data through its Products.</p>
              <p>In order to use our app, we require you to consent to the collection and processing of your Personal Data before you start using the app. If you do not agree with the terms of this Privacy Policy, you may not use in any manner the app.</p>
              <p>We are committed to protecting the privacy of all of its user’s Personal Data and providing a secure, user-controlled environment for the use of the app.</p>

              <h2 className='py-5'>PERSONAL DATA YOU PROVIDE</h2>
              <ul>
                  <li><strong>Account Credentials.</strong> In order to use the Hudel App Services, you are required to log in with an Account Authentication Mechanism (Name, and Email Address, or any third-party login such as Google Sign-in, Apple Sign-in). When creating an account, Hudel stores a hashed version of your Account Authentication Mechanism for authentication and/or verification purposes. We use a third-party service provider for verification and authentication.</li>
                  <li><strong>Profile Information.</strong> When you create an account, you choose to provide us with Personal Data about yourself. For example, in the Hudel App, you may provide your Personal Data, such as name, phone number, email address, and any other information that is required to positively identify the user.</li>
                  <li><strong>Photos, Videos, and Audio.</strong> If you upload a photo or video while using the Hudel App Services, you will be required to permit Hudel to access your camera, photo gallery, and microphone, in which case Hudel will have access to the photos and videos stored in your photo gallery.</li>
                  <li><strong>Location and Distance Information.</strong> When you use the Hudel App, we collect your precise location (e.g. your latitude and longitude) ("Location") to determine the distance in feet or meters (“Distance Information”). Should you choose not to allow the Hudel App to access your location, certain features (such as displaying nearby user profiles or features that include Live Location Sharing) of the Hudel Services will not function properly. You may also revoke this permission and disable the location services on your device. You can do so on an iPhone by going to Settings, Privacy, Location Services, Hudel; and on Android, by going to Settings, Hudel App, Permissions, Location.</li>
                  <li><strong>Feedback/Support.</strong> If you provide us feedback or contact customer support, we collect your name and e-mail address and possibly other Personal Data (e.g. IP address), as well as any other content that you send to us in order to reply and address any issue. If you contact customer support, we also collect and retain certain technical diagnostic data, such as your phone model. We may keep records of our communication with you, including any complaints we receive from you about other users (and from other users about you).</li>
              </ul>

              <h2 className='py-5'>PERMISSIONS REQUIRED</h2>
              <ul>
                  <li><strong>INTERNET</strong></li>
                  <li><strong>NOTIFICATION</strong></li>
                  <li><strong>CAMERA</strong></li>
                  <li><strong>GPS LOCATION</strong></li>
                  <li><strong>STORAGE</strong></li>
                  <li><strong>PHOTO LIBRARY</strong></li>
              </ul>
              <p>Link to the privacy policy of third-party service providers used by the app:</p>
              <ul>
                  <li>Apple App Store Services - <a href="https://developer.apple.com/app-store/app-privacy-details/">https://developer.apple.com/app-store/app-privacy-details/</a></li>
                  <li>Google Play Services - <a href="https://play.google.com/about/play-terms/">https://play.google.com/about/play-terms/</a></li>
                  <li>Google Firebase Services - <a href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a></li>
              </ul>

              <h2 className='py-5'>LOG DATA</h2>
              <p>We want to inform you that whenever you use our Service, in case of an error in the app, we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>

              <h2 className='py-5'>GENERAL DATA PROTECTION REGULATION (GDPR)</h2>
              <p>We are a Data Controller of your information.</p>
              <p>Hudel's legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
              <ul>
                  <li>Hudel needs to perform a contract with you.</li>
                  <li>You have given Hudel permission to do so.</li>
                  <li>Processing your personal information is in Hudel's legitimate interests.</li>
                  <li>Hudel needs to comply with the law.</li>
              </ul>
              <p>Hudel will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>
              <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</p>
              <p>You are entitled to the following rights under applicable laws:</p>
              <ol>
                  <li>The right to access: you may at any time request to access your personal data. Upon request, we will provide a copy of your personal data in a commonly used electronic form.</li>
                  <li>The right to rectification: you are entitled to obtain rectification of inaccurate personal data and to have incomplete personal data completed.</li>
                  <li>The right to erase: under certain circumstances (including processing on the basis of your consent), you may request us to delete your User Data. Please note that this right is not unconditional. Therefore, an attempt to invoke the right might not lead to an action from us.</li>
                  <li>The right to object: to certain processing activities conducted by us in relation to your personal data, such as our processing of your personal data based on our legitimate interest. The right to object also applies to the processing of your personal data for direct marketing purposes.</li>
                  <li>The right to data portability: you are entitled to receive your personal data (or have your personal data directly transmitted to another data controller) in a structured, commonly used and machine-readable format.</li>
              </ol>

              <h2 className='py-5'>YOUR PERSONAL DATA</h2>
              <p>We are using a strong backend for our Hudel App. In the backend, all the content will be stored. All Data will remain safe and secure. No, any single information/data will send anywhere.</p>
              <p>The requested data during signup are:</p>
              <ul>
                  <li>Phone number (For finding mutual friends)</li>
                  <li>Name (For user Identification)</li>
                  <li>Email Address (For user unique Identification)</li>
                  <li>School (School name which user belongs to)</li>
              </ul>

              <h2 className='py-5'>SECURITY</h2>
              <p>We value your trust in providing us with your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

              <h2 className='py-5'>SERVICE PROVIDERS</h2>
              <p>We may employ third-party companies and individuals due to the following reasons:</p>
              <ul>
                  <li>To facilitate our Service;</li>
                  <li>To provide the Service on our behalf;</li>
                  <li>To perform Service-related services; or</li>
                  <li>To assist us in analyzing how our Service is used.</li>
              </ul>
              <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

              <h2 className='py-5'>UPDATES OR CHANGES TO OUR PRIVACY POLICY</h2>
              <p>Occasionally, we may change or update this Privacy Policy to allow us to use or share your previously collected Personal Data for other purposes. If we would use your Personal Data in a manner materially different from that stated at the time of the collection, we will provide you with a notice on our Website and in our Hudel Mobile Application indicating that the Privacy Policy has been changed or updated and request you to agree with the updated or changed Privacy Policy.</p>

              <h2 className='py-5'>CONTACT US</h2>
              <p><a href="mailto:email@hudel.co">email@hudel.co</a></p>
            </div>
          </div>
          {/* END OF TOP SECTION */}

        </div> 
        <Footer />

      </div>
      )
  }
}

export default PrivacyPolicy;
