import React from 'react';
import {Helmet} from "react-helmet";
import { TextLoop } from "react-text-loop-next";
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';


import './css/index.css';
import 'animate.css'; // Import Animate.css


import Navbar from './components/Navbar';
import Footer from './components/Footer';

class HomePage extends React.Component {
  constructor(){
    super()
    this.state = {
      email : '',
      submitted : false,
      submitSuccess : true,
      errorMessage : '',
      isLoading : false,
      emailEmpty : false,
      pageLoad : true,
    }

  }

  componentDidMount() {
    // Simulate a 1-second loading delay before setting isLoading to false
    setTimeout(() => {
      this.setState({ pageLoad: false });
    }, 3000);
  }


  onChange = async (e) =>{
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  sendEmail = async (e) => {
    e.preventDefault();
    if(this.state.email === ''){
      this.setState({
        emailEmpty : true
      })
    }
    else{
      try {
        this.setState({
          isLoading : true,
          emailEmpty : false,
          submitted : false
        })
  
        const response = await axios.post('https://hudel-web-backend-production.up.railway.app/api/landing-page/sign-up/'+this.state.email);
  
        if (response.status === 200) {
          this.setState({
            submitted : true,
            submitSuccess : true,
            isLoading : false
  
          })
          
        } 
      } catch (error) {
        if (error.response) {
          this.setState({
            submitted : true,
            submitSuccess : false,
            isLoading : false,
            errorMessage : error.response.data.errorMessage

          })
        } else {
          console.error(error);
        }
      }
    }
  };

  render(){
    return(
      this.state.pageLoad === true ? 
      <div style={ {
        backgroundColor: '#0F024A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
            <img id='navbar-logo' className='animate__tada animate__animated animate__slow' src="/assets/Logo.png" alt="Logo" width="auto" height="40" />

      </div>
      :
      <div className="">
        <Helmet>
            <title>
                Hudel - Discover Communities in your School 
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div id="top-section">
        <Navbar />
          {/* TOP SECTION */}
          <ScrollAnimation animateIn="fadeIn" animateOnce={true} initiallyVisible={true}  duration={5}>
            <div className='row py-5 page-container max-width ' style={{minHeight:'90vh'}}>

              <div className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
                <img id='top-illustration' src='/assets/Top_Illustration.png' className='img-fluid animate__rotateInUpRight animate__animated animate__slower' style={{width:'350px'}} />
              </div>
              <div className='col-12 pt-5 pt-md-0 col-lg-6 d-flex flex-column justify-content-center align-items-start'>
                <div className='desktop-display w-100'>
                  <h1 className='' style={{color:'white'}}>Find 
                  <span className='ml-0 ml-md-3'>
                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                  <div>
                    <h1 className='mb-0 green-text' >Hoops Pickups</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orange-text' >Study Buddies</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 purple-text' >Intl. Students</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 blue-text' >Kpop Fans</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Gaming Clubs</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 green-text' >Soccer Fanatics</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orange-text' >Startup Friends</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 purple-text' >Housing Mates</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 blue-text' >Dance Groups</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Movie Clubs</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 green-text' >Varsity Clubs</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orange-text' >Course Explorers</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Travel Explorers</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 green-text' >Badminton Clubs</h1>
                  </div>

                  <div>
                    <h1 className='mb-0 orange-text' >Study Groups</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Game Nights</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Food Clubs</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Dating Groups</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Commuters</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Class Groups</h1>
                  </div>


                  </TextLoop>
                  </span>
                  </h1>
                  <h1 className='mb-0'  style={{color:'white'}}>in your School!</h1>
                </div>
                <div className='mobile-display d-none pt-5'>
                  <h1 className='' style={{color:'white'}}>Find </h1>

                  <span className='ml-0 ml-lg-3'>
                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                  <div>
                    <h1 className='mb-0 green-text' >Hoops Pickups</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orange-text' >Study Buddies</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 purple-text' >Intl. Students</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 blue-text' >Kpop Fans</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Gaming Clubs</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 green-text' >Soccer Fanatics</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orange-text' >Startup Friends</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 purple-text' >Housing Mates</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 blue-text' >Dance Groups</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Movie Clubs</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 green-text' >Varsity Clubs</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orange-text' >Course Explorers</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Travel Explorers</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 green-text' >Badminton Clubs</h1>
                  </div>

                  <div>
                    <h1 className='mb-0 orange-text' >Study Groups</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Game Nights</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Food Clubs</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Dating Groups</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Commuters</h1>
                  </div>
                  <div>
                    <h1 className='mb-0 orangepurple-text' >Class Groups</h1>
                  </div>


                  </TextLoop>
                  </span>
                  <h1 className='mb-0'  style={{color:'white'}}>in your School!</h1>
                </div>
                {/* <p className='pt-4' style={{color:'white'}}>Download on the app store!</p> */}
                <a href="https://apps.apple.com/ca/app/hudel/id6460048611" target="_blank"> 
                  <img src='/assets/Pre_Order_App_Store_White.svg' className='pt-4 img-fluid animate__tada animate__animated animate__slow' style={{width:'175px'}} />
                </a>

                {/* <div className='d-flex w-100 desktop-display'>
                  <p style={{width:'65%'}}>
                    <input className='px-4 py-2' required
                    style={{
                      borderRadius: '15px',
                      backgroundColor: "white",
                      border: "2px solid #D9D9D9",
                      fontFamily:'Inter Medium',
                      width:'100%'
                    }} 
                    type="email" onChange={this.onChange} name="email" placeholder="Insert your university email" />

                  </p>
                  {this.state.isLoading === false ?
                  <p className='mb-0'>
                    <button className="purple-button ml-4" onClick={this.sendEmail}> Send Link</button>
                  </p>
                  :
                  <p className='mb-0'>
                    <button className="purple-button ml-4" > Loading</button>
                  </p>
                  }

                </div>
                {(this.state.submitted === true && this.state.submitSuccess === true) &&
                <div className='px-3 py-2 mb-3 desktop-display' style={{borderRadius:'15px', border:'2px solid #00E109', backgroundColor:'#71A073',width:'65%'}}>
                    <p className='mb-0' style={{color:'#ffffff'}}>Success! Your School is on Hudel Beta, check your inbox!</p>
                </div>
                }
                {(this.state.submitted === true && this.state.submitSuccess === false) &&

                <div className='px-3 py-2 mb-3 desktop-display' style={{borderRadius:'15px', border:'2px solid #A90000', backgroundColor:'#978181',width:'65%'}}>
                    <p className='mb-0' style={{color:'#ffffff'}}>{this.state.errorMessage}</p>
                </div>
                }
                {(this.state.emailEmpty === true) &&

                <div className='px-3 py-2 mb-3 desktop-display' style={{borderRadius:'15px', border:'2px solid #A90000', backgroundColor:'#978181',width:'65%'}}>
                    <p className='mb-0' style={{color:'#ffffff'}}>Email is required..</p>
                </div>
                }
                <div className='mobile-display w-100 d-none'>
                  <p>
                    <input className='px-4 py-2' 
                      style={{
                        borderRadius: '15px',
                        backgroundColor: "white",
                        border: "2px solid #D9D9D9",
                        padding: "10px",
                        fontFamily:'Inter Medium',
                        width:'100%'
                      }} 
                      type="email"  onChange={this.onChange} name="email"  placeholder="Insert your university email" />
                    
                  </p>
                    {(this.state.submitted === true && this.state.submitSuccess === true) &&
                    <div className='px-3 py-2 mb-3 mobile-display d-none' style={{borderRadius:'15px', border:'2px solid #00E109', backgroundColor:'#71A073',width:'100%'}}>
                        <p className='mb-0' style={{color:'#ffffff'}}>Success! Your School is on Hudel Beta, check your inbox!</p>
                    </div>
                    }
                    {(this.state.submitted === true && this.state.submitSuccess === false) &&

                    <div className='px-3 py-2 mb-3 mt-3 mobile-display d-none' style={{borderRadius:'15px', border:'2px solid #A90000', backgroundColor:'#978181',width:'100%'}}>
                        <p className='mb-0' style={{color:'#ffffff'}}>{this.state.errorMessage}</p>
                    </div>
                    }
                    {(this.state.emailEmpty === true) &&

                    <div className='px-3 py-2 mb-3 mt-3 mobile-display d-none' style={{borderRadius:'15px', border:'2px solid #A90000', backgroundColor:'#978181',width:'100%'}}>
                        <p className='mb-0' style={{color:'#ffffff'}}>Email is required..</p>
                    </div>
                    }
                    {this.state.isLoading === false ?
                    <p className='mb-0'>
                      <button className="purple-button mb-4 w-100" onClick={this.sendEmail}>Send Link</button>
                    </p>
                    :
                    <p className='mb-0'>
                    <button className="purple-button mt-3 mb-4">Loading</button>
                    </p>
                    }
                    

                </div> */}
                <h6 className='pt-4 desktop-display' style={{color:'#BDBDBD',paddingRight:'100px'}}>We’re slowly onboarding more universities, stay tuned.</h6>
                <div className='mobile-display d-none'>
                  <h6 className='pt-4' style={{color:'#BDBDBD'}}>We’re slowly onboarding more universities, stay tuned.</h6>
                </div>
              </div>
            </div>

          </ScrollAnimation>
          {/* END OF TOP SECTION */}

          {/* DISCOVER COMMUNITIES SECTION */}
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} initiallyVisible={false}  duration={3}>
          <div className='row page-container max-width features-section' style={{maxHeight:'90vh'}}>
            <div className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <img src='/assets/gif/Discover Communities.gif' className='img-fluid gif-asset' style={{width:'400px'}} />
            </div>
            <div className='col-12 pt-md-0 col-lg-6 pr-0 pr-xl-5 d-flex flex-column justify-content-center align-items-start'>
                <div className='pr-0 pr-xl-5'>
                  <h1 style={{color:'white'}}>Discover Communities in your School </h1>
                  <h5 className='pt-4' style={{color:'#BDBDBD'}}>May it be school clubs, crypto, hockey, or mystery novels, there are always fellow enthusiasts out there, we’ll help you find them and everything you would want to know before you take the leap.</h5>

                </div>
            </div>
          </div>
          </ScrollAnimation>

          {/* END OF DISCOVER COMMUNITIES SECTION */}

          {/* Build your community SECTION */}
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} initiallyVisible={false}  duration={3}>
          <div className='row page-container  max-width features-section' style={{maxHeight:'90vh'}}>
            <div className='desktop-display col-12 pt-md-0 col-lg-6 pl-0 pl-xl-5 d-flex flex-column justify-content-center align-items-start'>
                  <div className='pr-0 pl-xl-5' style={{textAlign:'right'}}>
                    <h1 style={{color:'white'}}>Build your Community </h1>
                    <h5 className='pt-4' style={{color:'#BDBDBD'}}>Reaching the right people is what makes a great community. Hudel does the groundwork for you by directing people with shared interests your way.</h5>

                  </div>
            </div>
            <div className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <img src='/assets/gif/Build your community.gif' className='img-fluid  gif-asset' style={{width:'400px'}} />
            </div>
            <div className='mobile-display d-none col-12 pt-md-0 col-lg-6 pr-0 pr-xl-5 '>
                <div className='pr-0 pr-xl-5 d-flex flex-column justify-content-center align-items-start'>
                  <h1 style={{color:'white'}}>Build your Community  </h1>
                  <h5 className='pt-4' style={{color:'#BDBDBD'}}>Reaching the right people is what makes a great community. Hudel does the groundwork for you by directing people with shared interests your way.</h5>
                </div>
            </div>
          
          </div>
          </ScrollAnimation>

          {/* END OF Build your community SECTION */}
          {/* Chat and announcement SECTION */}
          <ScrollAnimation animateIn="fadeInRight" animateOnce={true} initiallyVisible={false}  duration={3}>
          <div className='row page-container  max-width features-section' style={{maxHeight:'90vh'}}>
            <div className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <img src='/assets/gif/Chat and announcement.gif' className='img-fluid  gif-asset' style={{width:'400px'}} />
            </div>
            <div className='col-12 pt-md-0 col-lg-6 pr-0 pr-xl-5 d-flex flex-column justify-content-center align-items-start'>
                <div className='pr-0 pr-xl-5'>
                  <h1 style={{color:'white'}}>Stay in the loop with Chats & Announcements</h1>
                  <h5 className='pt-4' style={{color:'#BDBDBD'}}>Check out what your communities are up to from the announcements, and get to know your fellow compadres once you join a community.</h5>

                </div>
            </div>
          </div>
          </ScrollAnimation>
          {/* END OF Chat and announcement SECTION */}

          {/* Need for feed SECTION */}
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} initiallyVisible={false}  duration={3}>
          <div className='row page-container  max-width features-section' style={{maxHeight:'90vh'}}>
            <div className='desktop-display col-12 pt-md-0 col-lg-6 pl-0 pl-xl-5 d-flex flex-column justify-content-center align-items-start'>
                  <div className='pr-0 pl-xl-5' style={{textAlign:'right'}}>
                    <h1 style={{color:'white'}}>Need for Feed </h1>
                    <h5 className='pt-4' style={{color:'#BDBDBD'}}>Digging more about what creates that spark in you made easy - choose your interests, and Hudel gets you closer to what you’re looking for, may they be clubs, communities, or groups.</h5>

                  </div>
            </div>
            <div className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <img src='/assets/gif/Need for feed.gif' className='img-fluid  gif-asset' style={{width:'400px'}} />
            </div>
            <div className='mobile-display d-none col-12 pt-md-0 col-lg-6 pr-0 pr-xl-5 '>
                <div className='pr-0 pr-xl-5 d-flex flex-column justify-content-center align-items-start'>
                  <h1 style={{color:'white'}}>Need for Feed </h1>
                  <h5 className='pt-4' style={{color:'#BDBDBD'}}>Digging more about what creates that spark in you made easy - choose your interests, and Hudel gets you closer to what you’re looking for, may they be clubs, communities, or groups.</h5>
                </div>
            </div>
          
          </div>
          </ScrollAnimation>
          {/* END OF Need for feed SECTION */}

          {/* Explore events SECTION */}
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} initiallyVisible={false}  duration={3}>
          <div className='row page-container max-width features-section' style={{maxHeight:'90vh'}}>
            <div className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <img src='/assets/gif/Explore Events.gif' className='img-fluid  gif-asset' style={{width:'400px'}} />
            </div>
            <div className='col-12 pt-md-0 col-lg-6 pr-0 pr-xl-5 d-flex flex-column justify-content-center align-items-start'>
                <div className='pr-0 pr-xl-5'>
                  <h1 style={{color:'white'}}>Explore Events</h1>
                  <h5 className='pt-4' style={{color:'#BDBDBD'}}>Whether it’s pickup basketball on a Friday or an afternoon park meetup with international students, Hudel keeps you in the loop with events, and yes, that includes which of your friends who are already in for it.</h5>

                </div>
            </div>
          </div>
          </ScrollAnimation>
          {/* END OF Explore events SECTION */}

          <div className='row max-width'>

            <div className='col-12 pt-5 mt-5 px-0 px-md-5 py-5 mb-5' >

              <div style={{textAlign:'center',minHeight:'50vh'}} className='px-5 pt-5 mt-5'>
                <p>
                  <button className="purple-button" onClick={this.scrollToTop}>Get Exclusive Beta</button>  
                </p>

                <h1 className='pt-5 desktop-display' style={{color:'white'}}>
                Hudel-ing to Your <br></br>
                <span style={{color:'#6638FF'}}>University</span> Soon!
                </h1>
                <h1 className='pt-5 mobile-display d-none' style={{color:'white'}}>
                Hudel-ing to Your
                <span style={{color:'#6638FF'}}> University</span> Soon!
                </h1>

                <h5 className='pt-4 px-0 px-md-5 desktop-display' style={{color:'#BDBDBD'}}>As we rally up to get Hudel to more universities, <br></br> stay tuned for our updated list <span> <a target='_blank' href='https://instagram.com/thehudel'>@thehudel</a></span> on instagram.</h5>
                <h5 className='pt-4 px-0 px-md-5 mobile-display d-none' style={{color:'#BDBDBD'}}>As we rally up to get Hudel to more universities, stay tuned for our updated list <span> <a target='_blank' href='https://instagram.com/thehudel'>@thehudel</a></span> on instagram.</h5>

              </div>

            </div>


            <ScrollAnimation animateIn="fadeIn" animateOnce={true} initiallyVisible={false}  duration={3}>

            <div className='col-12 p-0 d-flex flex-column justify-content-end align-items-center pt-5 mt-5'>
              <img src='/assets/Bottom_Illustration.png' className='desktop-display img-fluid' style={{width:'70%'}} />
              <img src='/assets/Mobile_Bottom_Illustration.png' className='mobile-display d-none img-fluid' style={{width:'100%'}} />

            </div>
            </ScrollAnimation>

          </div>
          
        </div> 

        <Footer />

      </div>
      )
  }
}

export default HomePage;
