import React from 'react';
import {Helmet} from "react-helmet";


import './css/index.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

class CommunityGuidelines extends React.Component {
  constructor(){
    super()
    this.state = {
    }

  }

  render(){
    return(
      <div className="">
        <Helmet>
            <title>
                Hudel Community Guidelines
            </title>
            <meta
                name="description"
                content="Hudel - Discover Communities in your School"
            />
        </Helmet>
        

        <div className='m-0' style={{backgroundColor : '#12083A'}}>
        <Navbar />
          {/* TOP SECTION */}
          <div className='row max-width py-5 page-container '>
            <div className='col-12 page-container' style={{color:'white',textAlign:'left'}}>
            <h1 style={{textAlign:'center'}} className='py-5'>Community Guidelines 💛</h1>
            <p>Greetings from Hudel! Our commitment is to provide a safe and welcoming space where genuine connections and vibrant communities thrive. To ensure the well-being of all our members and maintain the integrity of our platform, we have established the following Community Guidelines:</p>
            <ol>
                <li><strong>Respectful conduct:</strong> We prioritize a respectful and kind environment. Any behavior involving abuse, bullying, or harassment targeted to harm, intimidate, or discomfort others is strictly prohibited.</li>
                <li><strong>No Discrimination or Hate:</strong> Offensive, defamatory comments, hate speech, threats of violence or harm against any individual or group of people are not tolerated.</li>
                <li><strong>Personal attacks:</strong> No spamming or trolling; such actions disrupt the community and are against our guidelines.</li>
                <li><strong>Prohibited content:</strong> Do not post or share content that is violent, explicit, offensive, or illegal. This includes graphic imagery, explicit language, or content that promotes self-harm or harm to others.</li>
                <li><strong>Legal Use only:</strong> Hudel must be used for legal purposes only. Any illegal activities are strictly prohibited.</li>
                <li><strong>Continuous Improvement:</strong> We value feedback. If you have suggestions for improvements or concerns about the platform, please reach out to us.</li>
            </ol>
            <p>We encourage all members to familiarize themselves with our Community Guidelines, as these outline the standards of conduct and mutual respect we expect from everyone within the Hudel community. In the event that you come across any content or behavior that violates these guidelines, please report it immediately. Our moderation team is here to ensure a safe and welcoming environment for all.</p>
            <p>If you have any questions or concerns, we are always available for you at <a href="mailto:support@hudel.co">support@hudel.co</a>.</p>
            </div>
          </div>
          {/* END OF TOP SECTION */}

        </div> 
        <Footer />

      </div>
      )
  }
}

export default CommunityGuidelines;
