import React, {useState } from 'react';
import { Helmet } from 'react-helmet';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState} from 'draft-js';
import { convertToRaw } from 'draft-js';
import axios from 'axios';
import AdminNavbar from './components/AdminNavbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AdminBlogCreate = () => {
  
  const [blogData, setBlogData] = useState({
    content: 'safdasdf',
    datePublished: '2023-12-02T19:46:57.888Z',
    readDurationInMinutes: 0,
    title: '',
    shortDescription: '',
  });

  const [coverImage, setCoverImage] = useState(null); // Add this line
  const [contentImage, setContentImage] = useState(null); // Add this line
  const [editorState, setEditorState] = useState(EditorState.createEmpty()); // Create initial EditorState

  const notify = (message) => toast(message); // Declare notify correctly



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlogData((prevBlogData) => ({
      ...prevBlogData,
      [name]: value,
    }));
  };

  const createBlog = async (e) => {
    e.preventDefault()
    notify('Creating..')

    try {
      const headers = {
        'Content-Type': 'application/json',
        'accept': '*/*',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      };

      const url = `https://hudel-web-backend-development.up.railway.app/api/blogs`;

      const response = await axios.post(url, blogData, {
        headers: headers,
      });

      // Handle the response
      if (response.status === 200) {
        notify('Created!')
        window.location.href = '/admin/dashboard/blog';

        console.log('Blog created successfully:', response.data);
      }
    } catch (error) {
      notify('Create Error!')

      if (error.response) {
        console.error('Error creating blog:', error.response);
      } else {
        console.error(error);
      }
    }
  };


  return (
    <div className="">
      <Helmet>
        <title>Hudel Admin - Add New Blog</title>
        <meta
          name="description"
          content="Hudel - Discover Communities in your School"
        />
      </Helmet>

      <div className='m-0' style={{}}>
        <AdminNavbar />
          {/* TOP SECTION */}
          <div className='row m-0 py-5 page-container '>
            <div className='col-12'>
              <h1 style={{color:'white'}}>Hudel Add New Blog</h1>
            </div>
          </div>
          {/* END OF TOP SECTION */}
          {/* START OF BLOG LIST */}
          <div className='col-12 page-container'>
            <form className="row" onSubmit={createBlog}>
              
              <div className='col-12'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Blog Title</p>
                  <input required type="text" 
                  name="title"
                  value={blogData.title}
                  onChange={handleInputChange}
                  className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Insert Blog Title"/>
                </div>
              </div>
              <div className='col-12'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Short Description</p>
                  <textarea required
                  name="shortDescription"
                  value={blogData.shortDescription}
                  onChange={handleInputChange}
                  className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Insert Blog Short Short Description">

                  </textarea>
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Read Duration</p>
                  <input required type="text" 
                  name="readDurationInMinutes"
                  value={blogData.readDurationInMinutes}
                  onChange={handleInputChange}
                  className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Insert Read Duration"/>
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Date Published</p>
                  <input required type="date"
                  name="datePublished"
                  value={blogData.datePublished}
                  onChange={handleInputChange}
                  className="form-control form-control-user" aria-describedby="emailHelp" placeholder="Insert Date Added"/>
                </div>
              </div>
              <div className='col-12'>
                <div className="form-group">
                  <p className='mb-0 text-white'>Blog Content</p>
                  <Editor
                  editorState={editorState}
                  onEditorStateChange={(newEditorState) => {
                    setEditorState(newEditorState);
                    const updatedContent = JSON.stringify(
                      convertToRaw(newEditorState.getCurrentContent())
                    );
                    setBlogData((prevBlogData) => ({
                      ...prevBlogData,
                      content: updatedContent,
                    }));
                  }}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  editorStyle={{ backgroundColor: 'white' }}
                />
                  </div>
              </div>
              <div className='col-12 pb-5'>
                <div className="form-group">
                  <button type='submit' className="purple-button btn btn-primary btn-user btn-block mt-4">
                    Create Blog
                  </button>              
                  </div>
              </div>
            </form>
          </div>
          {/* END OF BLOG LIST */}
        </div> 
        <ToastContainer
            
          autoClose={3000}
        />
    </div>
  );
};

export default AdminBlogCreate;
